"use client";

import type { TagBase } from "@/hl-common/types/api/entities/Tags";
import { addTags, makeTagsFromUtmParams, syncTags } from "@/utils/localTags";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

export default function SaveTags({ tags }: { tags?: TagBase[] }) {
  const path = usePathname();
  const searchParams = useSearchParams();

  // attempt to sync tags whenever the path changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to depend on path, even though the actual value isn't used in the effect.
  useEffect(() => {
    syncTags();
  }, [path]);

  // parse and save utm params
  useEffect(() => {
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    const utmCampaign = searchParams.get("utm_campaign");
    const utmContent = searchParams.get("utm_content");
    const utmTerm = searchParams.get("utm_term");

    const utmTags = makeTagsFromUtmParams(
      utmSource,
      utmMedium,
      utmCampaign,
      utmContent,
      utmTerm,
    );

    const allTags = utmTags.concat(tags ?? []);

    if (allTags.length > 0) {
      // Store the tags in localStorage
      addTags(allTags);
      // and sync
      syncTags();
    }
  }, [searchParams, tags]);

  return null;
}
