"use client";

import type { IngestEventRequest } from "@/hl-common/types/api/entities/Events";
import { getStorage } from "./localStorage";

// we index our events by UUID to prevent duplicates
type EventStorage = Record<string, IngestEventRequest>;

// localStorage helpers
const localStorageKey = "events";

// load attempts to pull a hashmap from storage, or create an empty one
// it logs an error if the key is unparseable
const load = (userId: number) => {
  const key = localStorageKey + userId;
  const value = getStorage().getItem(key);
  if (!value) {
    return {} as EventStorage;
  }
  try {
    return JSON.parse(value) as EventStorage;
  } catch (e) {
    console.error(
      `localEvents load failed to parse key: ${key}`,
      "error:",
      e,
      "value:",
      value,
    );
    return {} as EventStorage;
  }
};

// save updates the localStorage with our event hash map
const save = (userId: number, events: EventStorage) => {
  getStorage().setItem(localStorageKey + userId, JSON.stringify(events));
};

// getRandomEvent pulls a random event from localstorage for processing in
// the EventContext, and also sets the queueSize in the context
export const getRandomEvent = (
  userId: number,
  setQueueSize: (qs: number) => void,
) => {
  const events = load(userId);
  const uuids = Object.keys(events);
  setQueueSize(uuids.length);

  if (uuids.length === 0) {
    return undefined;
  }

  const randomUuid = uuids[Math.floor(Math.random() * uuids.length)];
  return events[randomUuid];
};

// saveEvent adds an event to localstorage
export const saveEvent = (userId: number, event: IngestEventRequest) => {
  const events = load(userId);
  events[event.uuid] = event;
  save(userId, events);
};

// deleteEvent removes an event from localstorage
export const deleteEvent = (userId: number, uuid: string) => {
  const events = load(userId);
  delete events[uuid];
  save(userId, events);
};
