"use client";

import { tag_type } from "@/hl-common/types/api/PrismaEnums";
import type { TagBase } from "@/hl-common/types/api/entities/Tags";
import { v4 } from "uuid";
import { syncTags as apiSyncTags } from "./api/client";
import { ApiError } from "./api/fetch";
import { getStorage } from "./localStorage";

// localStorage helpers
const localStorageKey = "tags";

// guestId helps us when syncing tags for unauthenticated users
const guestIdKey = "guestId";

// ensure a guestId is set when getting it for the first time
export const getGuestId = () => {
  let guestId = getStorage().getItem(guestIdKey);
  if (!guestId) {
    guestId = v4();
    getStorage().setItem(guestIdKey, guestId);
  }

  return guestId;
};

// loadTags attempts to pull a list of tags from storage, or creates an empty one.
// it logs an error if the key is unparseable
const loadTags = () => {
  const value = getStorage().getItem(localStorageKey);
  if (!value) {
    return [] as TagBase[];
  }
  try {
    return JSON.parse(value) as TagBase[];
  } catch (e) {
    console.error(
      "localTags load failed to parse",
      "error:",
      e,
      "value:",
      value,
    );
    return [] as TagBase[];
  }
};

const save = (tags: TagBase[]) => {
  getStorage().setItem(localStorageKey, JSON.stringify(tags));
};

export const addTags = (newTags: TagBase[]) => {
  save(loadTags().concat(newTags));
};

// send tags to the server, and clear them if the server accepts them!
export const syncTags = async () => {
  const tags = loadTags();
  if (tags.length === 0) {
    return;
  }

  try {
    // optimistically empty the array before calling syncTags...
    // this allows tags recorded *during the API call* to be persisted in localStorage for future sync
    save([]);
    await apiSyncTags({ body: { guestId: getGuestId(), tags } });
  } catch (error) {
    if (error instanceof ApiError && error.statusCode === 400) {
      // failed validation - maybe unsyncable - don't restore them to localStorage
    } else {
      // we failed to sync for reasons other than validation failure
      // re-add these tags to localStorage to try again later...
      addTags(tags);
    }

    console.error("syncTags failed", error);
  }
};

export const makeTagsFromUtmParams = (
  utmSource: string | null,
  utmMedium: string | null,
  utmCampaign: string | null,
  utmContent: string | null,
  utmTerm: string | null,
): TagBase[] => {
  const tags: TagBase[] = [];

  if (utmSource) {
    tags.push({
      type: tag_type.UTM_SOURCE,
      family: utmCampaign || "<none>",
      name: utmSource,
    });
  }

  if (utmMedium) {
    tags.push({
      type: tag_type.UTM_MEDIUM,
      family: utmCampaign || "<none>",
      name: utmMedium,
    });
  }

  if (utmCampaign) {
    tags.push({
      type: tag_type.UTM_CAMPAIGN,
      family: utmCampaign,
      name: "<all>",
    });
  }

  if (utmContent) {
    tags.push({
      type: tag_type.UTM_CONTENT,
      family: utmCampaign || "<none>",
      name: utmContent,
    });
  }

  if (utmTerm) {
    tags.push({
      type: tag_type.UTM_TERM,
      family: utmCampaign || "<none>",
      name: utmTerm,
    });
  }

  return tags;
};
