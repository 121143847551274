"use client";

// Our global singleton storage. This will point either to window.localStorage
// or a MemoryStorage instance.
let storage: Storage | undefined = undefined;

// Returns window.localStorage if available, and an in-memory fallback
// otherwise.
export const getStorage = () => {
  if (storage !== undefined) {
    return storage;
  }

  if (hasLocalStorage()) {
    storage = window.localStorage;
    return storage;
  }

  storage = new MemoryStorage();
  return storage;
};

// Local storage check, taken from Modernizr:
// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
const hasLocalStorage = () => {
  const key = "x";
  try {
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

// MemoryStorage is a fallback for window.localStorage.
class MemoryStorage implements Storage {
  private data: Record<string, string> = {};

  get length(): number {
    return Object.keys(this.data).length;
  }

  key(index: number): string | null {
    return Object.keys(this.data)[index] || null;
  }

  getItem(key: string): string | null {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: Object.hasOwn would be preferred, but is not supported by old browsers
    return this.data.hasOwnProperty(key) ? this.data[key] : null;
  }

  setItem(key: string, value: string): void {
    const oldValue = this.getItem(key);
    if (value === oldValue) return;

    this.data[key] = value;
    window.dispatchEvent(
      // Emit a storage event. In principle, this should have a `storageArea:
      // this` property, but adding that property causes an error in Firefox.
      new StorageEvent("storage", {
        key: key,
        newValue: value,
        oldValue: oldValue,
        url: window.location.href,
      }),
    );
  }

  removeItem(key: string): void {
    const oldValue = this.getItem(key);
    if (oldValue === null) return;

    delete this.data[key];
    window.dispatchEvent(
      new StorageEvent("storage", {
        key: key,
        newValue: null,
        oldValue: oldValue,
        url: window.location.href,
      }),
    );
  }

  clear(): void {
    this.data = {};
    window.dispatchEvent(
      new StorageEvent("storage", {
        key: null,
        newValue: null,
        oldValue: null,
        url: window.location.href,
      }),
    );
  }
}
